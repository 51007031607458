// routes
import Router from './routes';
import { NavConfigContext } from '@mymeeinc/shared-web/contexts/NavConfigContext';
import navConfig from './NavConfig';
import { PATH_GUEST, PATH_LOGGED_IN } from './routes/paths';
import { useChat } from '@mymeeinc/shared-web/hooks/useChat';

export default function App() {
  const { conversations } = useChat('mainAppForNavConfig');

  return (
    <NavConfigContext.Provider
      value={{ navConfig: navConfig(conversations), PATH_LOGGED_IN, PATH_GUEST }}
    >
      <Router />
    </NavConfigContext.Provider>
  );
}
