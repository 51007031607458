import realm_config from './realm.json';
import { name, version } from '../package.json';
import { defaultSettings } from './config';
import ReactDOM from 'react-dom/client';
import { MainFrame } from '@mymeeinc/shared-web/mainFrame';
import MemberWebApp from './App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <MainFrame
    realm_config={realm_config}
    name={name}
    version={version}
    sentry_init={{
      dsn: 'https://bc58f2489b1848d3a9d4f0b622bc3efb@o685251.ingest.sentry.io/4505346670854144',
    }}
    defaultSettings={defaultSettings}
  >
    <MemberWebApp />
  </MainFrame>
);
