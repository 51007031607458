import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import LoggedInLayout from '@mymeeinc/shared-web/layouts/loggedIn';
import LogoOnlyLayout from '@mymeeinc/shared-web/layouts/LogoOnlyLayout';
// guards
import GuestGuard from '@mymeeinc/shared-web/guards/GuestGuard';
import AuthGuard from '@mymeeinc/shared-web/guards/AuthGuard';
import { version } from '../../package.json';
import { Loadable } from '@mymeeinc/shared-web/routes';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: 'payment', element: <Payment /> },
    {
      path: 'guest',
      element: (
        <GuestGuard>
          <LogoOnlyLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'redirect',
          element: <Redirect />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        { path: 'resend-confirmation', element: <ResendConfirmationEmail /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'confirm-email', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <LoggedInLayout version={version} />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <Landing /> },
        { path: 'mySymptoms', element: <MySymptoms /> },
        { path: 'myTrackings', element: <MyTrackings /> },
        { path: 'myCoach', element: <MyCoach /> },
        { path: 'mySessions', element: <MySessions /> },
        { path: 'myProfile', element: <UserProfile /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/account" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/Login')));
const Redirect = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/Redirect')));

const Register = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/ResetPassword')));
const ResendConfirmationEmail = Loadable(
  lazy(() => import('@mymeeinc/shared-web/pages/auth/ResendConfirmationEmail'))
);
const NewPassword = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/auth/VerifyCode')));

// MEMBER SPECIFIC
const Landing = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Landing')));
const MyCoach = Loadable(lazy(() => import('../pages/MyCoach')));
const MySessions = Loadable(lazy(() => import('../pages/MySessions')));
const MySymptoms = Loadable(lazy(() => import('../pages/MySymptoms')));
const MyTrackings = Loadable(lazy(() => import('../pages/MyTrackings')));

// USER
const UserProfile = Loadable(
  lazy(() => import('@mymeeinc/shared-web/pages/dashboard/UserProfile'))
);
const UserAccount = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/myAccount/MyAccount')));

// APP
const Chat = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Chat')));
const Calendar = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/CalendarPage')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import('@mymeeinc/shared-web/pages/dashboard/PermissionDenied'))
);

// MAIN
const Payment = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Payment')));
const Page500 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page500')));
const Page403 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page403')));
const Page404 = Loadable(lazy(() => import('@mymeeinc/shared-web/pages/Page404')));
